


















































































































































import { RESPONSE_STOCK_TYPE, STATE_STOCK_ADJUSTMENT } from "@enum/stock-adjustment.enum";
import { ResponseInventoryLine } from "@interface/inventory-line.interface";
import { ResponseStockAdjustment } from "@interface/stock-adjustment.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import Vue from "vue";
import { masterTypeService } from "@service/master-type.service";
import { ResponseListMasterType } from "@interface/master.interface";
import { QC_CONDITION } from "@enum/qc-condition.enum";
import { inventoryLineBatchService } from "@service/inventory-line-batch.service";
import { ResponseInventoryLineBatch, ResponseListInventoryLineBatch } from "@interface/inventory-line-batch.interface";
import { Mode } from "@/models/enums/global.enum";
import { createNamespacedHelpers } from "vuex";
import { IAuthorities } from "@/models/interface-v2/auth.interface";
import { formatterNumber, reverseFormatNumber } from "@/validator/globalvalidator";
import { DECIMAL_PLACES_QTY } from "@/models/constant/global.constant";
import { Decimal } from "decimal.js-light";

const { mapGetters } = createNamespacedHelpers("authStore");

export interface OpnameTableRow {
  batchId: string;
  batchNumber: string;
  key: any;
  productName: string;
  productCode: string;
  productId: string;
  qtyTotal: number;
  uom: string;
  uomId: string;
  qtyPhysical: number;
  qtyDifference: number;
  id: string;
  condition: string;
  conditionOther: string;
  conditionOtherToggle: boolean;
  no: number;
}

export default Vue.extend({
  name: "TableStockOpname",
  props: {
    locationId: {
      type: String,
      required: true,
    },
    detailReports: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      DECIMAL_PLACES_QTY,
      loading: {
        product: false,
        generate: false,
        qc: false,
        table: false,
      },
      paramProduct: {
        limit: 1000,
        page: 0,
        sorts: "createdDate:desc"
      } as RequestQueryParamsModel,
      dtListProduct: [] as ResponseInventoryLine[],
      dtSource: [] as OpnameTableRow[],
      productSearchBy: {
        location: "",
        name: ""
      } as { location: string, name: string },
      optUom: [] as {key: string, value: string}[],
      optQC: [] as { key: string, value: string }[],
      vmBatchNumber: "",
      flagDetailLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      userPrivileges: "GET_USER_PRIVILEGES"
    }),
    isSubmitted(): boolean {
      return this.detailReports.state === STATE_STOCK_ADJUSTMENT.SUBMITTED;
    },
    isCancelled(): boolean {
      return this.detailReports.state === STATE_STOCK_ADJUSTMENT.CANCELLED;
    },
    isDraft(): boolean {
      return this.detailReports.state === STATE_STOCK_ADJUSTMENT.DRAFT;
    },
    filteredProducts(): OpnameTableRow[] {
      return this.dtSource.filter(x => (this.vmBatchNumber ? x.batchNumber === this.vmBatchNumber : x));
    },
    isModeView(): boolean {
      return this.$route.meta.mode === Mode.VIEW;
    },
    hasPrivApprovalStockOpname(): boolean {
      return this.userPrivileges.find((x: IAuthorities) => x.key === "approval-stock-opname");
    }
  },
  watch: {
    locationId: {
      deep: true,
      immediate: false,
      handler() {
        if (this.$route.query.status !== "new" && !this.flagDetailLoaded) return;
        this.onchangeLocation();
      }
    },
    detailReports(): void {
      this.fillTable();
    }
  },
  mounted() {
    this.getListQC();
  },
  deactivated() {
    if (!this.isSubmitted && !this.isCancelled && !this.isDraft) this.dtSource = [];
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    getListInventoryLine(param: RequestQueryParamsModel): Promise<ResponseListInventoryLineBatch> {
      return inventoryLineBatchService.getListInventoryLineBatch(param);
    },
    getMasterType(params: RequestQueryParamsModel): Promise<ResponseListMasterType[]> {
      return masterTypeService.listMaster(params);
    },
    async fillProduct(): Promise<void> {
      try {
        this.loading.product = true;
        const res = await this.getListInventoryLine(this.paramProduct);
        this.showProductsLocation(res.data);
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loading.product = false;
        this.loading.table = false;
      }
    },
    showProductsLocation(data: ResponseInventoryLineBatch[]): void {
      this.dtSource = data.map((x, i) => {
        return {
          batchId: x.batchId,
          batchNumber: x.batchNumber,
          key: x.batchId || i,
          productName: x.product?.name || "",
          productCode: x.product?.code || "",
          productId: x.product?.id || "",
          qtyTotal: x.onHand,
          uom: x.uom?.unit || "",
          uomId: x.uom?.id || "",
          qtyPhysical: 0,
          qtyDifference: 0,
          id: "",
          condition: "",
          conditionOther: "",
          conditionOtherToggle: false,
          no: i + 1
        };
      });
      this.emitDataUpdate();
    },
    onchangeLocation(): void {
      if (!this.locationId) return;
      this.productSearchBy.location = `warehouseLocation.secureId~${this.locationId}`;
      this.productSearchBy.name = "";
      this.paramProduct.search = `${this.productSearchBy.location}_AND_onHand>0`;
      this.loading.table = true;
      this.fillProduct();
    },
    oninputQtyPhysical(record: OpnameTableRow): void {
      const { no } = record;
      const qtyDifference = new Decimal(record.qtyPhysical || 0).minus(record.qtyTotal || 0);
      this.dtSource[no - 1].qtyDifference = qtyDifference.absoluteValue().toNumber();
      this.emitDataUpdate();
    },
    emitDataUpdate(): void {
      this.$emit("onDataUpdate", { name: "opname", data: this.dtSource, deletedRow: [] });
    },
    fillTable(): void {
      const detail: ResponseStockAdjustment = {...this.detailReports};
      this.dtSource = [];
      const { dtSource } = this;
      if (
        detail.type === RESPONSE_STOCK_TYPE.STOCK_OPNAME &&
        detail.stockAdjustmentLines &&
        detail.stockAdjustmentLines.length
      ) {
        this.optUom = [];
        detail.stockAdjustmentLines.forEach((item, i) => {
          dtSource.push({
            key: i,
            productCode: item.product.code,
            productName: item.product.name,
            qtyTotal: item.qty,
            uom: item.uom.unit,
            uomId: item.uom.id,
            qtyPhysical: item.physicalQty,
            qtyDifference: item.differenceQty,
            id: item.id,
            productId: item.product.id,
            condition: item.qualityControl.condition,
            conditionOther: "",
            conditionOtherToggle: false,
            batchId: item.batchNumberId,
            batchNumber: item.batchNumber,
            no: i + 1,
          });
          // fill uom option from detail
          item.uomConversions.forEach(u => {
            this.optUom.push({ value: u.unitUomId, key: u.unitUom });
          });
        });
        this.dtSource = dtSource;
        this.flagDetailLoaded = true;
        this.emitDataUpdate();
      }
    },
    onselectCondition(): void {
      this.emitDataUpdate();
    },
    mapPriorityQc(value: string): number {
      if (value === QC_CONDITION.TO_BE_CONFIRMED) return 1;
      if (value === QC_CONDITION.COLOR_DEFECT) return 2;
      if (value === QC_CONDITION.ODOR_DEFECT) return 3;
      if (value === QC_CONDITION.PACKAGING_DEFECT) return 4;
      if (value === QC_CONDITION.COULD_NOT_BE_USED) return 5;
      return 0;
    },
    async getListQC(): Promise<void> {
      try {
        this.loading.qc = true;
        const listqc = await this.getMasterType({name: "QC_CONDITION"});
        const sortedQc = listqc.map(x => {
          return {
            key: x.id, value: x.value,
            no: this.mapPriorityQc(x.value)
          };
        });
        sortedQc.forEach(el => {
          this.optQC.push({ key: el.key, value: el.value });
        });
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loading.qc = false;
      }
    },
    addNewOption(record: OpnameTableRow): void {
      const { conditionOther } = record;
      this.optQC.push({key: conditionOther, value: conditionOther});
      record.conditionOther = "";
    },
  }
});
